import React, { useState } from "react"
import styled from "styled-components"
import BlogArticleFirst from "../components/BlogArticleFirst"
import BlogArticleFollowing from "../components/BlogArticleFollowing"
import { BlogEntry } from "../types/blog"
import { Link } from "gatsby"

const StyledBlogArticles = styled.div`
  text-align: center;

  a {
    &:hover {
      text-decoration: none;
    }
  }
`
const Button = styled.button`
  font-size: 28px;
  font-family: "Helvetica Neue LT W05_87 Hv Cn", sans-serif;
  background-color: #00aa9b;
  color: white;
  margin-top: 50px;

  a {
    background-color: #00aa9b;
    color: white;
    &:hover {
      text-decoration: none;
    }
  }
`
interface BlogArticleProps {
  blogData: BlogEntry[]
}

const BlogArticles: React.FC<BlogArticleProps> = ({ blogData }) => {
  const [loadAll, setLoadAll] = useState(false)

  let detailPage: String

  if (location.search.length > 0) {
    detailPage = location.search.split("?")[1]
  }

  return (
    <StyledBlogArticles>
      {blogData
        .slice(0, !loadAll ? 4 : blogData.length - 1)
        .map((item, index) => {
          if (
            (index === 0 && detailPage === undefined) ||
            item.link === `https://www.gesundheitsoptik.ch${detailPage}`
          ) {
            return <BlogArticleFirst key={item.guid} {...item} />
          } else if (index > 0 && detailPage == undefined) {
            return <BlogArticleFollowing key={item.guid} {...item} />
          }
        })}
      {!loadAll && detailPage === undefined && (
        <Button
          className="btn"
          onClick={() => {
            setLoadAll(!loadAll)
          }}
        >
          Mehr anzeigen
        </Button>
      )}
      {detailPage != undefined && (
        <Button>
          <Link to="/blog/">Zurück zum Blog</Link>
        </Button>
      )}
    </StyledBlogArticles>
  )
}

export default BlogArticles
