import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { BlogEntry } from "../types/blog"
import { Link } from "gatsby"

const StyledBlogArticle = styled.div`
  background-color: rgba(123, 164, 164, 0.1);
  margin-top: 30px;

  @media (min-width: 920px) {
    height: 380px;
  }

  a {
    display: block;
    height: 100%;
  }
`

const BlogContentWrapper = styled.div`
  position: relative;

  @media (min-width: 920px) {
    display: flex;
    height: 100%;
  }
`
const StyledImage = styled.div`
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 450px;

  @media (min-width: 920px) {
    width: 50%;
    height: 100%;
  }
`

const Title = styled.h1`
  font-size: 36px;
  font-family: "Helvetica Neue LT W05_87 Hv Cn", sans-serif;
  font-weight: 700;
  color: black;
  padding: 40px;
  text-align: center;

  @media (min-width: 920px) {
    text-align: left;
    font-size: 46px;
    padding: 60px 45px;
    width: 50%;
  }
`

const StyledLink = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: none;
  }
`

const BlogArticleFollowing: React.FC<BlogEntry> = ({
  description,
  enclosure,
  title,
  link,
}) => {
  let detailPage
  detailPage = link
  detailPage = detailPage.split(".ch")[1]
  detailPage = encodeURI(detailPage)
  return (
    <StyledBlogArticle>
      <Link to={`/blog/?${detailPage}`} target="_blank">
        <BlogContentWrapper>
          <Title>{title}</Title>
          {enclosure && (
            <StyledImage style={{ backgroundImage: `url(${enclosure})` }} />
          )}
        </BlogContentWrapper>
      </Link>
    </StyledBlogArticle>
  )
}

export default BlogArticleFollowing
