import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Loader from "../components/loader"
import convert from "xml-js"
import BlogArticles from "../components/BlogArticles"
import { BlogEntry, Item } from "../types/blog"

const Blog: React.FC = () => {
  const siteTitle = "Blog"
  const [isFetching, setisFetching] = useState(true)
  const [blogData, setBlogData] = useState<BlogEntry[]>([])

  useEffect(() => {
    fetch(`https://www.gesundheitsoptik.ch/?feed=post-teaser`)
      .then(response => response.text())
      .then(parsed =>
        convert.xml2js(parsed, { compact: true, nativeType: true })
      )
      .then((data: convert.ElementCompact) => {
        let blogEntries: BlogEntry[] = []
        data.rss.channel.item.map((item: Item) => {
          const blogEntry: BlogEntry = {
            guid: item.guid["_text"],
            title: item.title["_text"],
            link: item.link["_text"],
            description: item.description["_cdata"],
            enclosure: item.enclosure ? item.enclosure["_attributes"].url : "",
          }
          blogEntries.push(blogEntry)
        })
        setBlogData(blogEntries)
        setisFetching(false)
      })
  }, [])
  return (
    <Layout title={siteTitle}>
      <div className="wrapper">
        {isFetching ? (
          <Loader text="Der Blog wird geladen, bitten warten..." />
        ) : (
          <BlogArticles blogData={blogData} />
        )}
      </div>
    </Layout>
  )
}

export default Blog
