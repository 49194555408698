import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Logo from "../assets/schwarz-optik-brille.svg"
import { BlogEntry } from "../types/blog"
import SEO from "../components/seo"

const StyledBlogArticle = styled.div`
  margin-bottom: 50px;
`
const Title = styled.h1`
  font-size: 47px;
  font-family: "Helvetica Neue LT W05_35 Thin", sans-serif;
  text-align: center;
  padding-top: 45px;
  padding-bottom: 45px;
  font-weight: 100;
  color: black;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 800px) {
    font-size: 72px;
    padding-left: 0;
    padding-right: 0;
  }
`

const Description = styled.div`
  padding: 60px 40px;
  background-color: rgba(123, 164, 164, 0.1);

  p {
    font-size: 28px;
    line-height: 42px;
  }
`
const StyledLink = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: none;
  }
`
const StyledLogoWrapper = styled.div`
  text-align: center;
  padding-top: 60px;
`
const StyledLogo = styled.img`
  width: 115px;
`
const HeroImage = styled.div`
  height: 665px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: #00aa9b;
`

const BlogArticleFirst: React.FC<BlogEntry> = ({
  description,
  enclosure,
  title,
  link,
}) => {
  let siteTitle: String

  if (location.search.length > 0) {
    siteTitle = `${title} – Blog`
  } else {
    siteTitle = "Blog"
  }
  return (
    <StyledBlogArticle>
      <SEO title={siteTitle} />
      <StyledLink href={link} target="_blank">
        <div className="wrapper">
          {enclosure ? (
            <HeroImage style={{ backgroundImage: `url(${enclosure})` }} />
          ) : (
            <HeroImage />
          )}
          <Title>{title}</Title>
          <Description>
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <StyledLogoWrapper>
              <StyledLogo src={Logo} title="SCHWARZ Optik" />
            </StyledLogoWrapper>
          </Description>
        </div>
      </StyledLink>
    </StyledBlogArticle>
  )
}

export default BlogArticleFirst
