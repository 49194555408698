import React from "react"

interface LoaderProps {
  text: string
}

const Loader: React.FC<LoaderProps> = ({text}) => (
    <section className="loading">{text}</section>
)

export default Loader
